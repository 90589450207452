import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import { useIsInternational } from '@/hooks/useIsInternational';
import { GlobalType } from '@/types/global';
import { request } from '@/libs';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { t } from '@lingui/macro';
import { GlobalContext } from '@/pages';

type PositionType = {
  title: string;
  category: number | null;
  lang: GlobalType.LangType | 'ja';
};

const JoinUs: React.FC<{ isActive: boolean }> = () => {
  const { joinUsAndNewsSwiperInstance } = useContext(GlobalContext);
  const { lang, isLang } = useIsInternational();
  const [activePosition, setActivePosition] = useState<PositionType | null>(
    null
  );
  const [positionList, setPositionList] = useState<
    {
      department: string;
      id: number;
      title: string;
    }[]
  >([]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      joinUsAndNewsSwiperInstance?.updateSlides();
    }, 600);
    return () => {
      clearTimeout(timeId);
    };
  }, [positionList]);

  const positionTypes: {
    zh: PositionType[];
    jp: PositionType[];
    en: PositionType[];
    kr: PositionType[];
  } = {
    zh: [
      { title: '技术类', category: null, lang: 'zh' },
      { title: '美术类', category: null, lang: 'zh' },
      { title: '策划类', category: null, lang: 'zh' },
      { title: '市场/职能类', category: null, lang: 'zh' },
    ],
    jp: [
      { title: 'イラストレーター', category: 1, lang: 'ja' },
      { title: '3Dデザイナー', category: 8, lang: 'ja' },
      { title: '総合職', category: 9, lang: 'ja' },
      { title: 'アニメーションデザイナー', category: 3, lang: 'ja' },
    ],
    en: [
      { title: 'Art', category: 1, lang: 'en' },
      { title: 'Design', category: 2, lang: 'en' },
      { title: 'Animation', category: 3, lang: 'en' },
      { title: '3D Art', category: 8, lang: 'en' },
    ],
    kr: [
      {
        title: '2D 그래픽',
        category: 1,
        lang: 'kr',
      },
      {
        title: '애니메이션',
        category: 3,
        lang: 'kr',
      },
      {
        title: '3D 그래픽',
        category: 8,
        lang: 'kr',
      },
    ],
  };

  useEffect(() => {
    if (activePosition) {
      getPositionList(activePosition);
    }
  }, [activePosition, lang]);

  const getPositionList = async ({ category, lang }: PositionType) => {
    try {
      const { res } = await request(
        `https://gwadm.manjuu.com/api/v1/recruitment`,
        {
          method: 'GET',
          data: {
            lang,
            category,
            limit: 100,
            page: 1,
          },
        }
      );
      setPositionList(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const clickPosition = (p?: PositionType) => {
    if (isLang('zh')) {
      window.open('https://app.mokahr.com/apply/manjiu/40770#/');
      return;
    }
    if (p) {
      setPositionList([]);
      setActivePosition(p);
    }
  };

  const positionListShowHeight = useMemo(() => {
    return 0.74 + (91 * positionList.length) / 100;
  }, [positionList]);

  return (
    <div
      className={classNames([
        styles.mj_JoinUs_container,
        activePosition && styles.mj_joinUs_container_show,
      ])}>
      <div className={styles.mj_joinUs_international_title}>
        <div
          className={
            styles.mj_joinUs_international_title_text
          }>{t`加入我们`}</div>
        <div className={styles.mj_joinUs_international_title_subTitle}>
          {isLang('kr') ? '/ Manjuu Korea' : '/ Manjuu Games'}
        </div>
      </div>
      <div className={styles.mj_joinUs_position_body}>
        {lang !== 'zh-tw' &&
          positionTypes[lang].map((p, i) => (
            <React.Fragment key={i}>
              <div
                onClick={() => {
                  if (
                    activePosition &&
                    activePosition.category === p.category
                  ) {
                    setActivePosition(null);
                    setPositionList([]);
                  } else {
                    clickPosition(p);
                  }
                }}
                className={classNames([
                  styles.mj_joinUs_position_item,
                  activePosition?.category === p.category &&
                    styles.mj_joinUs_position_item_active,
                ])}>
                <div className={styles.mj_joinUs_position_name}>
                  <div className={styles.mj_joinUs_position_name_dot} />
                  <div className={styles.mj_joinUs_position_name_text}>
                    {p.title}
                  </div>
                </div>
                <div className={styles.mj_joinUs_position_subName}>
                  <div>RECRUIT</div>
                  <div>
                    <Icon
                      className={classNames([
                        styles.mj_joinUs_position_right_arrow,
                        p.category === activePosition?.category &&
                          styles.mj_joinUs_position_right_arrow_active,
                      ])}
                      fontClass="jiantou-41"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: `${
                    p.category === activePosition?.category
                      ? positionListShowHeight
                      : 0
                  }rem`,
                }}
                className={classNames([styles.mj_joinUs_global_position_list])}>
                <div className={styles.mj_joinUs_global_position_title}>
                  <div
                    className={
                      styles.mj_joinUs_global_position_title_item
                    }>{t`职位`}</div>
                  <div
                    className={
                      styles.mj_joinUs_global_position_title_item
                    }>{t`所属部门`}</div>
                </div>
                {positionList.map(p => (
                  <a
                    rel="noopener noreferrer"
                    href={`/${lang}/position/${p.id}`}
                    target={'_blank'}
                    key={p.id}
                    className={styles.mj_joinUs_global_position_list_item}>
                    <div>
                      <span>{p.title}</span>
                    </div>
                    <div>
                      <span>{p.department}</span>
                    </div>
                  </a>
                ))}
              </div>
            </React.Fragment>
          ))}
      </div>

      {isLang('zh') && (
        <div
          onClick={() => {
            clickPosition();
          }}
          className={styles.mj_joinUs_moka}>
          前往投递
        </div>
      )}

      <div className={styles.mj_joinUs_bottom_text}>WELCOME TO JOIN MANJUU</div>
    </div>
  );
};

export default JoinUs;
