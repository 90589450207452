import React, { useContext } from 'react';
import styles from './index.module.less';
import CloseButton from 'components/CloseButton';
import Icon from 'components/Icon';
import { t } from '@lingui/macro';
import Image from 'next/image';
import { ManjuuQRCodePath } from 'components/ProductsDetails';
import classNames from 'classnames';
import Router from 'next/router';
import { DialogContext } from 'components/Dialog/components/DialogContainer';

const ContactUs: React.FC = () => {
  const { hide } = useContext(DialogContext);
  const isLang = (la: string | undefined) => {
    return Router.router?.locale === la;
  };

  const openSocialLink = (url: string | URL | undefined) => {
    window.open(url);
  };

  return (
    <div className={styles.mj_ContactUs_container}>
      <div className={styles.mj_ContactUs_title}>
        <div className={styles.mj_ContactUs_title_compName}>{t`蛮啾网络`}</div>
        {!isLang('en') && (
          <div className={styles.mj_ContactUs_title_subTitle}>
            {isLang('kr') ? '/ MANJUU KOREA' : '/ MANJUU GAMES'}
          </div>
        )}
      </div>

      <div className={styles.mj_ContactUs_content}>
        <div className={styles.mj_ContactUs_content_title}>
          <div
            className={
              styles.mj_ContactUs_content_titleName
            }>{t`联系我们`}</div>
          {!isLang('en') && !isLang('kr') && (
            <div className={styles.mj_ContactUs_content_subTitleName}>
              CONTACT US
            </div>
          )}
        </div>
        <div className={styles.mj_ContactUs_contactWay}>
          {!isLang('jp') && (
            <div className={styles.mj_ContactUs_contactWay_content}>
              {t`邮箱：cs@manjuu.com`}
            </div>
          )}

          {isLang('jp') && (
            <>
              <div className={styles.mj_ContactUs_contactWay_content}>
                社名： 株式会社Manjuu（Manjuu, Inc.）
              </div>
              <div className={styles.mj_ContactUs_contactWay_content}>
                住所： 〒101-0022 東京都千代田区神田練塀町300番
                <div style={{ textIndent: '55px' }}>
                  住友不動産秋葉原駅前ビル3階
                </div>
              </div>
              <div className={styles.mj_ContactUs_contactWay_content}>
                代表者： 代表取締役社長　林書茵
              </div>
              <div className={styles.mj_ContactUs_contactWay_content}>
                設立： 2019年10月
              </div>
              <div className={styles.mj_ContactUs_contactWay_content}>
                事業内容： ゲームの企画・開発
              </div>
              <div className={styles.mj_ContactUs_contactWay_content}>
                {t`邮箱：cs@manjuu.com`}
              </div>
            </>
          )}
        </div>

        {(isLang('zh') || isLang('jp')) && (
          <>
            <div className={styles.mj_ContactUs_content_title}>
              <div
                className={
                  styles.mj_ContactUs_content_titleName
                }>{t`社交媒体`}</div>
              <div className={styles.mj_ContactUs_content_subTitleName}>
                SOCIAL MEDIA
              </div>
            </div>
            <ul className={styles.mj_ContactUs_contactWay_social}>
              {isLang('jp') && (
                <>
                  <li
                    className={styles.mj_ContactUs_contactWay_socialItem}
                    onClick={() => {
                      openSocialLink(
                        'https://twitter.com/manjuu_japan?s=21&t=pHBi39aniq7qfFXToo5qNg'
                      );
                    }}>
                    <Icon fontClass="ICON-37" />
                  </li>
                  <li
                    className={styles.mj_ContactUs_contactWay_socialItem}
                    onClick={() => {
                      openSocialLink('https://youtube.com/@manjuugames7368');
                    }}>
                    <Icon fontClass="ICON-36" />
                  </li>
                </>
              )}
              {isLang('zh') && (
                <>
                  <li
                    className={styles.mj_ContactUs_contactWay_socialItem}
                    onClick={() => {
                      openSocialLink('https://weibo.com/6124450240');
                    }}>
                    <Icon fontClass="ICON-34" />
                  </li>
                  <li
                    className={styles.mj_ContactUs_contactWay_socialItem}
                    onClick={() => {
                      openSocialLink('https://space.bilibili.com/626332915');
                    }}>
                    <Icon fontClass="ICON-35" />
                  </li>
                  <li
                    className={classNames([
                      styles.mj_ContactUs_contactWay_socialItem,
                      styles.mj_ContactUs_contactWay_socialItem_weChat,
                    ])}>
                    <div
                      className={
                        styles.mj_ContactUs_contactWay_socialItem_weChat_QRCode
                      }>
                      <Image
                        className={
                          styles.mj_ContactUs_contactWay_wechat_QRCodeImage
                        }
                        width={136}
                        height={136}
                        src={ManjuuQRCodePath}
                        alt="QRCode"></Image>
                    </div>
                    <Icon fontClass="ICON-33" />
                  </li>
                </>
              )}
            </ul>
          </>
        )}

        <div onClick={hide} className={styles.mj_ContactUs_bottom_btn}>
          <CloseButton
            svgProps={{ fill: '#232323' }}
            className={styles.mj_drawer_closeBtn}
          />
          <span>CLOSE</span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
