import { Root } from 'react-dom/client';

interface HideDialogProps {
  root: Root;
  rootElement: HTMLDivElement;
}

export const hideDialog = ({
  root,
  rootElement,
}: HideDialogProps): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      const rootEleArrayList = Array.from(rootElement.children);

      const timeId = setTimeout(() => {
        root.unmount();

        for (const child of rootEleArrayList) {
          if (child.children.length === 0 && rootElement.contains(child)) {
            rootElement.removeChild(child);
          }
        }

        resolve(true);
        clearTimeout(timeId);
      }, 180);
    } catch (err) {
      reject(err);
    }
  });
};
