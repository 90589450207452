import React, {
  createContext,
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import styles from './drawer.module.less';
import classNames from 'classnames';
import { Property } from 'csstype';
import CloseButton from 'components/CloseButton';

type direction = 'right' | 'left' | 'top' | 'bottom';

type DrawerProps = {
  visible: boolean;
  isShowMask?: boolean;
  clickMaskClosable?: boolean;
  placement?: direction;
  children?: ReactNode;
  workSpaceSize?: number;
  onClose?: (params: boolean) => void;
  delay?: Property.TransitionDelay<string>;
  offsetTop?: number;
  offsetRight?: number;
  width?: number | string;
  isShowCloseButton?: boolean;
  height?: string;
  dark?: boolean;
  maskOffsetTop?: number;
};

export const DrawerContext = createContext<{ visible: boolean }>({
  visible: false,
});

const Drawer: React.FC<DrawerProps> = props => {
  const {
    placement = 'right',
    delay,
    children,
    visible,
    clickMaskClosable = true,
    isShowMask = true,
    onClose,
    offsetTop = 0,
    offsetRight = 0,
    workSpaceSize = 300,
    width,
    height,
    dark = true,
    isShowCloseButton = true,
    maskOffsetTop = 0,
  } = props;
  const maskRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // document.body.style.overflow = visible ? 'hidden' : 'auto';
    if (!visible) {
      onClose?.(visible);
    }
  }, [visible]);

  const getPlacementCss = (direction: direction): CSSProperties => {
    const top = {
      top: 0,
      left: 0,
      width: '100%',
      height: `${workSpaceSize}px`,
    };

    const right = {
      top: 0,
      right: 0,
      width: `${workSpaceSize}px`,
    };

    const bottom = {
      bottom: 0,
      width: '100%',
      height: `${workSpaceSize}px`,
    };

    const left = {
      top: 0,
      left: 0,
      width: `${workSpaceSize}px`,
    };

    switch (direction) {
      case 'top':
        return top;
      case 'right':
        return right;
      case 'bottom':
        return bottom;
      case 'left':
        return left;
      default:
        return right;
    }
  };

  const maskClose = (ev?: React.MouseEvent<HTMLElement>) => {
    if (visible && ev?.target === maskRef.current) {
      onClose?.(false);
    }
  };
  return (
    <DrawerContext.Provider value={{ visible }}>
      <div
        onClick={maskClose}
        ref={maskRef}
        style={{ top: `${maskOffsetTop}px` }}
        className={
          styles[
            `mj_drawer_${dark ? 'dark' : 'light'}_mask_${
              clickMaskClosable
                ? visible
                  ? isShowMask
                    ? 'show'
                    : 'showNoMask'
                  : 'close'
                : 'close'
            }`
          ]
        }
      />
      <div
        style={{
          ...getPlacementCss(placement as 'right' | 'left' | 'top' | 'bottom'),
          minWidth:
            typeof width === 'string' ? width : width ? `${width}px` : '100vh',
          top: `${offsetTop}px`,
          right: `${
            offsetRight - (isShowCloseButton ? (visible ? 0 : 44) : 4)
          }px`,
          height: height ? height : `max-content`,
          transitionDelay: delay,
        }}
        className={classNames([
          styles.mj_drawer_container,
          styles[`mj_drawer_${visible ? 'show' : 'close'}_${placement}`],
        ])}>
        <>
          {isShowCloseButton && (
            <CloseButton
              className={styles.mj_drawer_closeBtn}
              onClick={() => {
                onClose?.(false);
              }}
            />
          )}
        </>
        {children}
      </div>
    </DrawerContext.Provider>
  );
};

export default Drawer;
