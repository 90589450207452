import React, { useContext, useEffect, useState } from 'react';
import styles from './index.module.less';
import { GlobalContext } from '@/pages';
import classNames from 'classnames';
import Image from 'next/image';
import homepagePoster from '@/public/images/homepage.jpg';
import Icon from 'components/Icon';

const HomePage: React.FC<{
  isActive: boolean;
  isVisible: boolean;
  isPrev: boolean;
}> = ({ isVisible, isPrev }) => {
  const { homePageSwiperInstance } = useContext(GlobalContext);
  const [isShow, setIsShow] = useState<boolean>(true);

  useEffect(() => {
    setIsShow(isVisible || isPrev);
  }, [isVisible, isPrev]);

  const clickNextPage = () => {
    homePageSwiperInstance?.slideNext();
  };

  return (
    <div
      className={classNames([
        styles.mj_homePage_container,
        !isShow && styles.mj_homePage_container_hide,
      ])}>
      <div className={styles.mj_homePage_video_body}>
        <Image
          id="homePic"
          className={styles.mj_homePage_image}
          fill
          priority
          src={homepagePoster}
          alt="manjuu pic"
        />
      </div>
      <div
        onClick={clickNextPage}
        className={classNames([styles.mj_homePage_scrollTips])}>
        <Icon
          className={styles.mj_homePage_scrollTips_svg}
          fontClass="ICON-39"
        />
      </div>
    </div>
  );
};

export default HomePage;
