import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import { t } from '@lingui/macro';
import { useIsInternational } from '@/hooks/useIsInternational';

const ProductDetails: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const { lang, isLang } = useIsInternational();
  const [isShowDesc, setIsShowDesc] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowDesc(isActive);
    }, 580);
  }, [isActive]);

  const clickLinkOpenNewPage = (url: string) => {
    if (url) {
      window.open(url);
    }
  };

  return (
    <div
      style={{ pointerEvents: isActive ? 'auto' : 'none' }}
      className={styles.mj_productDetails_container}>
      <div className={styles.mj_productDetails_content}>
        <div
          className={classNames([
            styles.mj_productDetails_title_container,
            (isLang('kr') || isLang('jp')) &&
              styles.mj_productDetails_title_container_krOjp,
          ])}>
          <div
            className={classNames([
              styles.productsDetails_title_body,
              (isLang('kr') || isLang('jp')) &&
                styles.productsDetails_title_body_krOjp,
              isShowDesc && styles.productsDetails_title_body_show,
            ])}>
            <div
              className={
                styles.productsDetails_title_name
              }>{t`蓝色星原：旅谣`}</div>
            {!isLang('en') && (
              <div
                className={classNames([
                  styles.productsDetails_title_subTitle,
                  (isLang('kr') || isLang('jp')) &&
                    styles.productsDetails_title_subTitle_krOjp,
                ])}>
                / AZUR PROMILIA
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames([
            styles.mj_productDetails_desc_body,
            lang == 'jp' && styles.mj_productDetails_desc_body_JP,
            lang == 'kr' && styles.mj_productDetails_desc_body_KR,
          ])}>
          <div
            lang={lang == 'zh-tw' ? 'zh' : lang}
            className={classNames([
              styles.mj_productDetails_desc,
              isShowDesc && styles.mj_productDetails_desc_show,
            ])}
            dangerouslySetInnerHTML={{
              __html: t`《蓝色星原：旅谣》是由蛮啾网络推出的一款全新星宠结伴 幻想大世界RPG。向往星原的奇幻旅途，将与你再次重逢。<br/><br/>星临者，欢迎回到普罗米利亚！`,
            }}
          />
        </div>
      </div>

      <div
        className={classNames([
          styles.productsDetails_link_body,
          isShowDesc && styles.productsDetails_link_body_show,
        ])}>
        <div
          onClick={() => {
            const location = `https://azurpromilia${
              isLang('zh') ? '.manjuu' : ''
            }.com/${isLang('zh') ? 'home' : `${lang}/home`}/`;

            clickLinkOpenNewPage(location);
          }}
          className={classNames([
            styles.productsDetails_link_website,
            styles.productsDetails_link_btn,
          ])}>
          <div
            className={classNames([
              styles.productsDetails_link_website_dot,
              styles.productsDetails_link_website_dot_left,
            ])}
          />
          {t`前往官网`}
          <div
            className={classNames([
              styles.productsDetails_link_website_dot,
              styles.productsDetails_link_website_dot_right,
            ])}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
