import React, { useState, useEffect, useContext, useRef } from 'react';
import Image from 'next/image';
import SwiperCore, { Autoplay, Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import p1_02 from '@/public/images/products/p1/p1_02.png';
import p1_03 from '@/public/images/products/p1/p1_03.png';
import p1_04 from '@/public/images/products/p1/p1_04.png';
import p1_05 from '@/public/images/products/p1/p1_05.png';
import p3_01 from '@/public/images/products/p3/p3_01.jpg';
import p3_02 from '@/public/images/products/p3/p3_02.jpg';
import p3_04 from '@/public/images/products/p3/p3_04.jpg';
import p3_05 from '@/public/images/products/p3/p3_05.jpg';
import styles from './index.module.less';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { GlobalContext } from '@/pages';
import ProductDetails from 'components/ProductDetails';
import ProductDetails3 from 'components/ProductDetails3';
export type ProductsTypes = 'p1' | 'p2' | 'p3';
SwiperCore.use([Autoplay]);
import { useIsInternational } from '@/hooks/useIsInternational';

const Products: React.FC<{
  isActive: boolean;
  isPrev: boolean;
}> = ({ isActive }) => {
  const { homePageSwiperInstance, bodySwiperInstance, activePage } =
    useContext(GlobalContext);
  const [isShowProductsDetails, setIsShowProductsDetails] =
    useState<boolean>(false);
  const [productPicSwiperInstance, setProductPicSwiperInstance] =
    useState<SwiperClass | null>(null);
  const [startY, setStartY] = useState<number>(0);
  // const [startD, setStartD] = useState<string>('down');
  const ref = useRef<HTMLDivElement>(null);
  const [swiperInstanceCard2, setSwiperInstanceCard2] =
    useState<SwiperClass | null>(null);
  const [swiperInstanceCard1, setSwiperInstanceCard1] =
    useState<SwiperClass | null>(null);
  const [currentKey, setCurrentKey] = useState('0');
  const swiperIndex1 = useRef(0);
  const swiperIndex2 = useRef(0);

  const { isLang } = useIsInternational();

  useEffect(() => {
    if (
      ref.current &&
      bodySwiperInstance &&
      productPicSwiperInstance &&
      homePageSwiperInstance
    ) {
      if (isShowProductsDetails) {
        if (
          startY === 0 &&
          (bodySwiperInstance?.activeIndex > 0 ||
            homePageSwiperInstance?.activeIndex > 0)
        ) {
          productPicSwiperInstance &&
            productPicSwiperInstance?.updateProgress();

          bodySwiperInstance?.enable();
          homePageSwiperInstance?.disable();
        }
      } else {
        if (
          startY === 0 &&
          bodySwiperInstance?.activeIndex === 0 &&
          homePageSwiperInstance.activeIndex > 0
        ) {
          //  productPicSwiperInstance?.updateProgress();

          homePageSwiperInstance?.enable();
          bodySwiperInstance?.disable();
        }
      }
      //   if (!activePage) {
      //  //   productPicSwiperInstance?.autoplay?.pause();

      //   } else {
      //   //  productPicSwiperInstance && productPicSwiperInstance?.autoplay?.start();

      //   }
    }
  }, [
    ref,
    startY,
    activePage,
    bodySwiperInstance,
    homePageSwiperInstance,
    isShowProductsDetails,
  ]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const productsGroup = {
    p1: [
      {
        url: p1_02,
      },
      {
        url: p1_03,
      },
      {
        url: p1_04,
      },
      {
        url: p1_05,
      },
    ],
    p2: [],
    p3: [
      {
        url: p3_01,
      },
      {
        url: p3_02,
      },

      {
        url: p3_04,
      },
      {
        url: p3_05,
      },
    ],
  };
  const addLine1 = (index: number) => {
    setCurrentKey(activeIndex == 0 ? 'p1' + index : 'p3' + index);
  };
  const SwiperConfig: SwiperProps = {
    speed: 400,
    spaceBetween: 0,
    onSwiper(swiper) {
      setProductPicSwiperInstance(swiper);
    },
    onSlideChange(s) {
      if (s.realIndex == 1) {
        swiperInstanceCard1?.autoplay?.pause();

        swiperInstanceCard2?.autoplay?.start();
      } else {
        swiperInstanceCard2?.autoplay?.pause();
        swiperInstanceCard1?.autoplay?.start();
      }
    },
    loop: false,
    autoplay: false,
    onTouchMove(swiper, e) {
      e.stopPropagation();
    },
    onTouchEnd(swiper, e) {
      e.stopPropagation();
    },
    loopedSlides: 1,
    simulateTouch: true,
    slidesPerView: 1,
    onTransitionStart(swiper) {
      // if (swiper.activeIndex == 0) {
      //   swiperIndex1.current += 1;
      //   const realIndex =
      //     (swiperIndex1.current - 1 + productsGroup['p3'].length) %
      //     productsGroup['p1'].length;
      //   addLine1(realIndex);
      // }
      addLine1(swiper.activeIndex);
      // addLine1(swiper);
    },
  };

  const SwiperConfig1: SwiperProps = {
    speed: 400,
    spaceBetween: 0,
    onSwiper(swiper) {
      setSwiperInstanceCard1(swiper);
    },

    loop: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    onTouchMove(swiper, e) {
      e.stopPropagation();
    },
    onTouchEnd(swiper, e) {
      e.stopPropagation();
    },

    onTransitionStart() {
      if (activeIndex == 0) {
        swiperIndex1.current += 1;
        const realIndex =
          (swiperIndex1.current - 1 + productsGroup['p3'].length) %
          productsGroup['p1'].length;
        addLine1(realIndex);
      }
      // activeIndex == 0 && addLine1(swiper);
    },
    loopedSlides: 1,
    simulateTouch: true,
    slidesPerView: 1,
  };
  const SwiperConfig2: SwiperProps = {
    speed: 400,
    spaceBetween: 0,
    onSwiper(swiper) {
      setSwiperInstanceCard2(swiper);
    },
    onSlideChange() {
      // setActiveSwiper2(s.realIndex);
    },
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    onTouchMove(swiper, e) {
      e.stopPropagation();
    },
    onTouchEnd(swiper, e) {
      e.stopPropagation();
    },
    loopedSlides: 1,
    simulateTouch: true,
    slidesPerView: 1,
    onTransitionStart() {
      if (activeIndex == 1) {
        swiperIndex2.current += 1;
        const realIndex =
          (swiperIndex2.current - 1 + productsGroup['p3'].length) %
          productsGroup['p3'].length;
        console.log(realIndex, 'realIndex>>>');
        addLine1(realIndex);
      }
    },
  };

  const onTouchStart = (event: any) => {
    setStartY(event.targetTouches[0].pageY);
    if (!isShowProductsDetails && bodySwiperInstance) {
      bodySwiperInstance.disable();
    }
  };
  const onTouchMove = (event: any) => {
    if (bodySwiperInstance) {
      if (startY - event.targetTouches[0].pageY > 100) {
        setIsShowProductsDetails(true);
      }

      if (startY - event.targetTouches[0].pageY < -100) {
        setIsShowProductsDetails(false);
      }
    }
  };
  const onTouchEnd = () => {
    setStartY(0);
  };

  useEffect(() => {
    if (productPicSwiperInstance) {
      productPicSwiperInstance?.slideTo(activeIndex, 400);
      if (activeIndex == 0) {
        swiperInstanceCard1?.slideTo(0, 400);
      } else {
        swiperInstanceCard2?.slideTo(0, 400);
      }
    }
  }, [productPicSwiperInstance, activeIndex]);

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      style={{ background: isActive ? '#FFF' : 'transparent' }}
      className={classNames([
        styles.mj_products_container,
        !isActive && styles.mj_products_container_noActive,
      ])}>
      {' '}
      <div
        className={classNames([
          styles.mj_products_animation,
          isShowProductsDetails && styles.showScal,
        ])}
        style={{
          height: `calc(var(--vh) - ${
            isShowProductsDetails ? (!isLang('kr') ? 4.57 : 5.08) : 0
          }rem)`,
          transition: '0.6s',
        }}>
        {' '}
        <Swiper className={styles.swiperW100} {...SwiperConfig}>
          <SwiperSlide key={'p10'}>
            {' '}
            <Swiper
              style={{
                height: '100%',
              }}
              loop={activeIndex == 0 ? true : false}
              className={classNames([styles.swiperWrapper, styles.swiperW100])}
              {...SwiperConfig1}>
              {productsGroup['p1']?.map((p, idx) => {
                return (
                  <SwiperSlide
                    key={'p1' + 'pic' + idx}
                    style={{
                      height: '100%',
                    }}>
                    <div
                      className={classNames([
                        styles.mj_products_loop_pic,
                        !isActive && styles.mj_products_swiper_noActive,
                      ])}>
                      {/* <div
                        style={{ backgroundImage: `url(${p.url.src})` }}
                        className={styles.mj_aboutUs_loop_picSelf}></div> */}
                      <Image
                        fill
                        priority
                        src={p.url}
                        alt="Manjuu Pic"
                        crossOrigin="anonymous"
                        className={styles.mj_aboutUs_loop_picSelf}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </SwiperSlide>
          <SwiperSlide key={'p30'}>
            <Swiper
              resistanceRatio={0}
              className={classNames([styles.swiperWrapper, styles.swiperW100])}
              {...SwiperConfig2}
              loop={activeIndex == 1 ? true : false}>
              {productsGroup['p3']?.map((p, idx) => {
                return (
                  <SwiperSlide key={'p3' + 'pic' + idx}>
                    <div
                      className={classNames([
                        styles.mj_products_loop_pic,
                        !isActive && styles.mj_products_swiper_noActive,
                      ])}>
                      <Image
                        fill
                        priority
                        src={p.url}
                        alt="Manjuu Pic"
                        crossOrigin="anonymous"
                        className={styles.mj_aboutUs_loop_picSelf}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </SwiperSlide>
        </Swiper>
        <div
          className={styles.bar_bottom}
          style={{ bottom: `${isShowProductsDetails ? 0.63 : 0.74}rem` }}>
          {' '}
          <div
            className={classNames([
              styles.mj_products_video,
              !isActive && styles.mj_products_video_hide,
            ])}
            style={{
              width: `${isShowProductsDetails ? 2.56 : 7.5}rem`,
              transition: '0.6s',
            }}>
            <div
              ref={ref}
              className={classNames([
                styles.mj_products_video_playDesc,
                activeIndex == 0 && styles.mj_products_p1,
                activeIndex == 1 && styles.mj_products_p3,
              ])}>
              <div
                className={classNames([
                  styles.mj_products_flex_card,
                  styles.mj_products_flex_card_1,
                  activeIndex == 0 && styles.mj_products_mask,
                ])}
                onTouchStart={e => {
                  setActiveIndex(0);

                  e.stopPropagation();
                }}>
                {!isShowProductsDetails && (
                  <span className={styles.title}>{t`碧蓝航线`}</span>
                )}
              </div>

              <div
                className={classNames([
                  styles.mj_products_flex_card,
                  styles.mj_products_flex_card_2,
                  activeIndex == 1 && styles.mj_products_mask,
                  ,
                ])}
                onTouchStart={e => {
                  setActiveIndex(1);
                  e.stopPropagation();
                }}>
                {!isShowProductsDetails && (
                  <span className={styles.title}>{t`蓝色星原：旅谣`}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mj_products_swiper_process}>
          {
            <div
              data-id={currentKey}
              key={currentKey}
              id="line1_test"
              className={styles.test_proce}
            />
          }
        </div>
      </div>
      <div
        className={styles.mj_ProductDetails_body}
        style={{
          opacity: isShowProductsDetails ? 1 : 0,
          transform: `translateY(5.19rem)`,
        }}>
        {activeIndex == 0 ? (
          <ProductDetails isActive={isShowProductsDetails} />
        ) : (
          <ProductDetails3 isActive={isShowProductsDetails} />
        )}
      </div>
      <div
        onClick={() => {
          setIsShowProductsDetails(true);
        }}
        className={styles.mj_products_bottom_bar}>
        <Icon
          className={styles.mj_homePage_scrollTips_svg}
          fontClass="ICON-39"
        />
      </div>
    </div>
  );
};

export default Products;
