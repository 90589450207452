import React, { ReactNode, useContext } from 'react';
import styles from './Header.module.less';
import { DialogContext } from 'components/Dialog/components/DialogContainer';
import CloseButton from 'components/CloseButton';

export interface DialogHeaderProps {
  closeIcon?: ReactNode;
}

const Header: React.FC<DialogHeaderProps> = ({ closeIcon }) => {
  const { hide } = useContext(DialogContext);

  return (
    <div className={styles.Dialog_header_container}>
      <div title="关闭" className={styles.Dialog_header_close} onClick={hide}>
        {closeIcon || <CloseButton svgProps={{ fill: '#ffe66f' }} />}
      </div>
    </div>
  );
};

export default Header;
