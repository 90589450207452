import React, { useContext } from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import { ProductsTypes } from 'components/Products';
import Icon from 'components/Icon';
import { t } from '@lingui/macro';
import Image from 'next/image';
import { useIsInternational } from '@/hooks/useIsInternational';
import { bgc_img } from '@/pages';
import { DrawerContext } from 'components/Drawer';

export const ManjuuQRCodePath = require('@/public/images/QRCode.jpeg');
export const AZURLANEQRCode = require('@/public/images/AZURLANEQRCode.jpeg');

const ProductsDetails: React.FC<{
  activeProductsType: ProductsTypes;
  setActiveProductsType: React.Dispatch<ProductsTypes>;
}> = ({ activeProductsType, setActiveProductsType }) => {
  const { lang, isLang, isInternational } = useIsInternational();
  const { visible } = useContext(DrawerContext);
  const clickLinkOpenNewPage = (url: string) => {
    if (url) {
      window.open(url);
    }
  };

  return (
    <div
      className={classNames([
        styles.productsDetails_container,
        visible && styles.productsDetails_container_show,
      ])}>
      <Image className={styles.mj_container_bgc} fill src={bgc_img} alt="pic" />
      <div
        className={classNames([
          styles.productsDetails_title_body,
          (isLang('kr') || isLang('jp')) &&
            styles.productsDetails_title_body_krOjp,
        ])}>
        <div className={styles.productsDetails_title_name}>{t`碧蓝航线`}</div>
        {!isLang('en') && (
          <div
            className={classNames([
              styles.productsDetails_title_subTitle,
              (isLang('kr') || isLang('jp')) &&
                styles.productsDetails_title_subTitle_krOjp,
            ])}>
            / AZUR LANE
          </div>
        )}
      </div>
      <div className={styles.productsDetails_introduce}>
        <div
          className={styles.productsDetails_introduce_title}>{t`产品简介`}</div>
        {!isLang('en') && !isLang('kr') && (
          <div className={styles.productsDetails_introduce_subtitle}>
            INTRODUCTION
          </div>
        )}
      </div>
      <div className={styles.productsDetails_detail}>
        {t`公司代表作《碧蓝航线》于2017年6月2日开始全平台公测，并已登陆海内外市场，取得优秀的成绩。
        游戏以舰船为题材，玩家可通过丰富的玩法，收集并养成强力的角色与装备，指挥属于自己的舰队征战四海。`}
      </div>
      <div className={styles.productsDetails_link_body}>
        <div
          onClick={() => {
            const localeWebsite = {
              zh: 'https://game.bilibili.com/blhx/',
              ['zh-tw']: 'https://www.azurlane.tw/',
              jp: 'https://www.azurlane.jp/',
              kr: 'https://azurlane.xdg.com/',
              en: 'https://azurlane.yo-star.com/',
            };
            clickLinkOpenNewPage(localeWebsite[lang]);
          }}
          className={classNames([
            styles.productsDetails_link_website,
            styles.productsDetails_link_btn,
          ])}>
          <div
            className={classNames([
              styles.productsDetails_link_website_dot,
              styles.productsDetails_link_website_dot_left,
            ])}
          />
          {t`前往官网`}
          <div
            className={classNames([
              styles.productsDetails_link_website_dot,
              styles.productsDetails_link_website_dot_right,
            ])}
          />
        </div>

        {isLang('zh') && (
          <>
            <div
              onClick={() => {
                clickLinkOpenNewPage('https://space.bilibili.com/233114659');
              }}
              className={classNames([
                styles.productsDetails_link_btn,
                styles.productsDetails_link_bilibili,
              ])}>
              <Icon fontClass="ICON-35" />
            </div>

            <div
              onClick={() => {
                clickLinkOpenNewPage('https://weibo.com/azurlane');
              }}
              className={classNames([
                styles.productsDetails_link_btn,
                styles.productsDetails_link_weibo,
              ])}>
              <Icon fontClass="ICON-23" />
            </div>

            <div
              className={classNames([
                styles.productsDetails_link_btn,
                styles.productsDetails_link_wechat,
              ])}>
              <div className={styles.productsDetails_link_wechat_QRCode}>
                <Image
                  className={styles.productsDetails_link_wechat_QRCodeImage}
                  width={136}
                  height={136}
                  src={AZURLANEQRCode}
                  alt="QRCode"
                />
              </div>
              <Icon fontClass="ICON-33" />
            </div>
          </>
        )}
        {isInternational && (
          <>
            {isLang('zh-tw') ? (
              <div
                onClick={() => {
                  clickLinkOpenNewPage('https://www.facebook.com/azurlaneTW');
                }}
                className={classNames([
                  styles.productsDetails_link_btn,
                  styles.productsDetails_link_facebook,
                ])}>
                <Icon fontClass="ICON-38" />
              </div>
            ) : (
              <div
                onClick={() => {
                  const localeWebsite = {
                    zh: '',
                    ['zh-tw']: '',
                    jp: 'https://twitter.com/azurlane_staff',
                    kr: 'https://twitter.com/azurlanekorea',
                    en: 'https://twitter.com/AzurLane_EN',
                  };
                  clickLinkOpenNewPage(localeWebsite[lang]);
                }}
                className={classNames([
                  styles.productsDetails_link_btn,
                  styles.productsDetails_link_twitter,
                ])}>
                <Icon fontClass="ICON-37" />
              </div>
            )}
            {isLang('kr') ? (
              <div
                onClick={() => {
                  clickLinkOpenNewPage('https://cafe.naver.com/azurlanekorea');
                }}
                className={classNames([
                  styles.productsDetails_link_btn,
                  styles.productsDetails_link_cafe,
                ])}>
                <Icon fontClass="cafe-40" />
              </div>
            ) : (
              <div
                onClick={() => {
                  const localeWebsite = {
                    zh: '',
                    ['zh-tw']: 'https://www.youtube.com/@azurlanetw',
                    jp: 'https://www.youtube.com/@azurlane_staff',
                    kr: '',
                    en: 'https://www.youtube.com/@AzurLaneOfficialYostar',
                  };
                  clickLinkOpenNewPage(localeWebsite[lang]);
                }}
                className={classNames([
                  styles.productsDetails_link_btn,
                  styles.productsDetails_link_youtube,
                ])}>
                <Icon fontClass="ICON-36" />
              </div>
            )}
          </>
        )}
      </div>

      <div className={styles.productsDetails_switchProducts_button_body}>
        <div
          onClick={() => {
            if (activeProductsType === 'p1') {
              setActiveProductsType('p3');
            }
            // if (activeProductsType === 'p2') {
            //   setActiveProductsType('p1');
            // }
            // if (activeProductsType === 'p3') {
            //   setActiveProductsType('p2');
            // }
          }}
          className={styles.productsDetails_switchProducts_button}></div>
        <div
          onClick={() => {
            if (activeProductsType === 'p1') {
              setActiveProductsType('p2');
            }
            // if (activeProductsType === 'p2') {
            //   setActiveProductsType('p3');
            // }
            // if (activeProductsType === 'p3') {
            //   setActiveProductsType('p1');
            // }
          }}
          className={styles.productsDetails_switchProducts_button}></div>
      </div>
    </div>
  );
};

export default ProductsDetails;
