import type { NextPage } from 'next';
import { GetStaticProps } from 'next';
import styles from './index.module.less';
import { loadTranslation } from '@/libs';
import Header from 'components/Header';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper';
import HomePage from 'components/HomePage';
import Products from 'components/Products';
import AboutUs from 'components/AboutUs';
import News from 'components/News';
import { useInnerHeight } from '@/hooks/useInnerHeight';
import SwiperCore, { EffectCreative, FreeMode } from 'swiper';
import { useIsInternational } from '@/hooks/useIsInternational';
import Image from 'next/image';
import classNames from 'classnames';
import JoinUs from 'components/JoinUs';
import Footer from 'components/Footer';
import horizontal from '@/public/images/horizontal.png';
import Cookies from 'js-cookie';
import { t } from '@lingui/macro';
import { setTimeout } from 'timers';

export const bgc_img = require('@/public/images/page_background.png');

SwiperCore.use([EffectCreative, FreeMode]);

export enum PageType {
  HomePage,
  Products,
  AboutUs,
  JoinUs,
  NEWS,
  Others,
}

export const getStaticProps: GetStaticProps = async ctx => {
  const translation = await loadTranslation(
    ctx.locale!,
    process.env.NODE_ENV === 'production'
  );
  return {
    props: {
      translation,
    },
  };
};

export const GlobalContext = createContext<{
  homePageSwiperInstance: SwiperClass | null;
  bodySwiperInstance: SwiperClass | null;
  bottomSwiperInstance: SwiperClass | null;
  joinUsAndNewsSwiperInstance: SwiperClass | null;
  activePage: number;
  setActivePage?: React.Dispatch<number>;
}>({
  homePageSwiperInstance: null,
  bodySwiperInstance: null,
  bottomSwiperInstance: null,
  joinUsAndNewsSwiperInstance: null,
  activePage: 0,
});

const Home: NextPage = () => {
  const [homePageSwiperInstance, setHomePageSwiperInstance] =
    useState<SwiperClass | null>(null);
  const [bodySwiperInstance, setBodySwiperInstance] =
    useState<SwiperClass | null>(null);
  const [bottomSwiperInstance, setBottomSwiperInstance] =
    useState<SwiperClass | null>(null);
  const [joinUsAndNewsSwiperInstance, setJoinUsAndNewsSwiperInstance] =
    useState<SwiperClass | null>(null);
  const [activePage, setActivePage] = useState<PageType>(PageType.HomePage);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isActiveBackgroundImage, setIsActiveBackgroundImage] =
    useState<boolean>(false);
  const [backgroundImageOffsetTop, setBackgroundImageOffsetTop] =
    useState<number>(0);
  const [backgroundImageTranTime, setBackgroundImageTranTime] =
    useState<number>(0);
  const { isLang, lang } = useIsInternational();

  useEffect(() => {
    if (!Cookies.get('lang')) {
      Cookies.set('lang', lang, { expires: 30 });
    }
  }, [lang]);

  useInnerHeight();

  const SwiperConfig: SwiperProps = {
    spaceBetween: 0,
    slidesPerView: 1,
    speed: 600,
    direction: 'vertical',
    resistanceRatio: 0,
  };

  const HomePageSwiperConfig: SwiperProps = {
    effect: 'creative',
    creativeEffect: {
      prev: {
        translate: [0, '-20%', -1],
      },
      next: {
        translate: [0, '100%', 0],
        scale: 1.62,
      },
    },
    ...SwiperConfig,
    slidesPerView: 'auto',
    onSwiper(swiper) {
      setHomePageSwiperInstance(swiper);
    },
    onTouchEnd(swiper) {
      setTimeout(() => {
        setActivePage(swiper?.activeIndex);
      }, 20);
    },
    simulateTouch: true,
  };

  const bodySwiperConfig: SwiperProps = {
    onSwiper(swiper) {
      setBodySwiperInstance(swiper);
    },
    onProgress(swiper, progress) {
      const offsetTopValue = 300 - Math.abs(progress) * 300;
      setIsActiveBackgroundImage(offsetTopValue === 0);
      if (swiper.activeIndex === 0) {
        setBackgroundImageOffsetTop(offsetTopValue);
        setBackgroundImageTranTime(0);
      } else if (swiper.activeIndex === 1) {
        setBackgroundImageOffsetTop(300 - Math.abs(progress) * 300);
      }
    },
    onTouchMove(swiper) {
      if (swiper.activeIndex === 1) {
        setBackgroundImageTranTime(0);
      }
    },
    onTouchEnd(swiper) {
      if (swiper.activeIndex === 1) {
        setBackgroundImageTranTime(600);
        setIsActiveBackgroundImage(swiper?.activeIndex === 1);
      }
      setTimeout(() => {
        if (
          swiper?.activeIndex === 0 &&
          homePageSwiperInstance?.activeIndex === 1 &&
          bodySwiperInstance?.activeIndex === 0
        ) {
          setActivePage(1);
        }
        if (
          swiper?.activeIndex === 1 &&
          homePageSwiperInstance?.activeIndex === 1 &&
          bodySwiperInstance?.activeIndex === 1 &&
          bottomSwiperInstance?.activeIndex === 0
        ) {
          setActivePage(2);
        }
      }, 20);
    },
    parallax: true,
    ...SwiperConfig,
  };

  const bottomSwiperConfig: SwiperProps = {
    ...SwiperConfig,
    onSwiper(swiper) {
      setBottomSwiperInstance(swiper);
    },
    onTouchEnd(swiper) {
      setTimeout(() => {
        if (
          swiper.activeIndex === 1 &&
          joinUsAndNewsSwiperInstance?.activeIndex === 0
        ) {
          setActivePage(PageType.JoinUs);
        }
      }, 20);
    },
  };

  const joinUsAndNewsSwiperConfig: SwiperProps = {
    ...SwiperConfig,
    slidesPerView: 'auto',
    freeMode: true,
    direction: 'vertical',
    onSwiper(swiper) {
      setJoinUsAndNewsSwiperInstance(swiper);
    },
    onResize(swiper) {
      const timeId = setTimeout(() => {
        swiper.updateSlides();
        swiper.slideTo(swiper.activeIndex);
        clearTimeout(timeId);
      }, 200);
    },
    onSlideChange(swiper) {
      if (
        bottomSwiperInstance?.activeIndex === 1 &&
        bodySwiperInstance?.activeIndex === 1 &&
        homePageSwiperInstance?.activeIndex === 1
      ) {
        if (swiper?.activeIndex === 0) {
          setActivePage(PageType.JoinUs);
        }
        if (swiper?.activeIndex === 1) {
          setActivePage(PageType.NEWS);
        }
      }
    },
  };
  const [verial, setVerial] = useState(true);
  const resizeHandle = () => {
    let W = window.innerWidth;
    let H = window.innerHeight;
    if (W > H) {
      setVerial(false);
    } else {
      setTimeout(() => {
        setVerial(true);
      }, 300);
    }
  };
  useEffect(() => {
    resizeHandle();
    window.addEventListener('resize', resizeHandle);
    return () => {
      window.removeEventListener('resize', resizeHandle);
    };
  }, []);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);
  return (
    <GlobalContext.Provider
      value={{
        activePage,
        setActivePage,
        bodySwiperInstance,
        homePageSwiperInstance,
        bottomSwiperInstance,
        joinUsAndNewsSwiperInstance,
      }}>
      <div ref={containerRef} className={styles.mj_container}>
        <Header />
        {true && (
          <Swiper className={styles.swiper_container} {...bodySwiperConfig}>
            <Image
              style={{
                opacity: activePage >= 1 ? 1 : 0,
                transform: `translateY(${backgroundImageOffsetTop}px)`,
                transition: `${backgroundImageTranTime}ms`,
              }}
              className={classNames([
                styles.mj_container_bgcImage,
                isActiveBackgroundImage && styles.mj_container_bgcImage_active,
              ])}
              fill
              src={require(`@/public/images/bgc_cat.jpg`)}
              alt="image1"
            />

            <SwiperSlide>
              {/* 首页到产品详情页 */}
              <Swiper
                {...HomePageSwiperConfig}
                className={classNames([
                  styles.swiper_container,
                  styles.swiper_homePageContainer,
                ])}>
                <SwiperSlide>
                  {({ isActive, isPrev, isVisible }) => {
                    return (
                      <HomePage
                        isActive={isActive}
                        isVisible={isVisible}
                        isPrev={isPrev}
                      />
                    );
                  }}
                </SwiperSlide>
                <SwiperSlide style={{ transition: '0s' }}>
                  {({ isActive, isPrev }) => (
                    <div>
                      {!loading && (
                        <Products isPrev={isPrev} isActive={isActive} />
                      )}
                    </div>
                  )}
                </SwiperSlide>
              </Swiper>
            </SwiperSlide>

            <SwiperSlide style={{ opacity: activePage === 0 ? 0 : 1 }}>
              <Swiper
                className={styles.swiper_container}
                {...bottomSwiperConfig}>
                <div className={styles.dimmer} />
                <SwiperSlide>
                  {({ isActive }) => <AboutUs isActive={isActive} />}
                </SwiperSlide>

                {!isLang('zh-tw') && (
                  <SwiperSlide style={{ height: '100%' }}>
                    <Swiper
                      {...joinUsAndNewsSwiperConfig}
                      className={styles.swiper_container}>
                      <SwiperSlide
                        className={styles.mj_joinUsAndNewsSwiper_SwiperSlide}>
                        {({ isActive }) => <JoinUs isActive={isActive} />}
                      </SwiperSlide>
                      {isLang('zh') && (
                        <SwiperSlide
                          className={styles.mj_joinUsAndNewsSwiper_SwiperSlide}>
                          {({ isActive }) => <News isActive={isActive} />}
                        </SwiperSlide>
                      )}
                      <SwiperSlide
                        className={styles.mj_joinUsAndNewsSwiper_SwiperSlide}>
                        <Footer />
                      </SwiperSlide>
                    </Swiper>
                  </SwiperSlide>
                )}
              </Swiper>
            </SwiperSlide>
          </Swiper>
        )}
      </div>
      {!verial && (
        <div className={styles.mj_landscape_tips}>
          <Image
            className={styles.mj_landscape_tips_image}
            src={horizontal}
            alt=""
          />
          <div className={styles.mj_landscape_tips_text}>
            {t`为了更好的浏览体验，请保持竖屏观看！`}
          </div>
        </div>
      )}
    </GlobalContext.Provider>
  );
};

export default Home;
