import { ReactNode } from 'react';
import { createRoot, Root } from 'react-dom/client';

export const renderDialog = ({
  rootElement,
  reactDialog,
}: {
  rootElement: HTMLDivElement;
  reactDialog: ReactNode;
}): { root: Root } => {
  const div = document.createElement('div');
  rootElement.appendChild(div);
  const root = createRoot(div);
  root.render(reactDialog);
  return {
    root,
  };
};
