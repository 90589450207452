import React, { useContext, useMemo, useRef, useState } from 'react';
import styles from './index.module.less';
import { GlobalContext, PageType } from '@/pages';
import classNames from 'classnames';
import { t } from '@lingui/macro';
import { useIsInternational } from '@/hooks/useIsInternational';
import CloseButton from 'components/CloseButton';
import Icon from 'components/Icon';
import { dialog } from 'components/Dialog';
import ContactUs from 'components/ContactUs';
import Cookies from 'js-cookie';
import { clickBaiduEvent } from '@/libs';
import Image from 'next/image';
export const LogoPath = require('@/public/images/Logo-white.png');

const Header: React.FC<any> = () => {
  const {
    activePage,
    setActivePage,
    homePageSwiperInstance,
    bodySwiperInstance,
    bottomSwiperInstance,
    joinUsAndNewsSwiperInstance,
  } = useContext(GlobalContext);
  const { isLang } = useIsInternational();
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const [isShowLangSelect, setIsShowLangSelect] = useState<boolean>(false);
  const maskRef = useRef<HTMLDivElement>(null);

  const { isInternational, lang } = useIsInternational();

  const NavList = [
    { name: t`首页`, show: true, type: PageType.HomePage },
    { name: t`产品介绍`, show: true, type: PageType.Products },
    { name: t`关于我们`, show: true, type: PageType.AboutUs },
    { name: t`加入我们`, show: !isLang('zh-tw'), type: PageType.JoinUs },
    { name: t`新闻动态`, show: !isInternational, type: PageType.NEWS },
    { name: t`联系我们`, show: true, type: PageType.Others },
  ];

  const langMap = {
    zh: '简体中文',
    ['zh-tw']: '繁體中文',
    jp: '日本语',
    kr: '한국어',
    en: 'English',
  };

  const clickNav = (index: number) => {
    if (
      homePageSwiperInstance &&
      bodySwiperInstance &&
      (isLang('zh-tw') || bottomSwiperInstance) &&
      (isLang('zh-tw') || joinUsAndNewsSwiperInstance)
    ) {
      if (setActivePage) {
        setActivePage(index);
      }
      bodySwiperInstance.enable();
      homePageSwiperInstance.enable();
      bottomSwiperInstance?.enable();
      joinUsAndNewsSwiperInstance?.enable();

      if (index === PageType.HomePage) {
        bodySwiperInstance.slideTo(0);
        homePageSwiperInstance.slideTo(0);
        bottomSwiperInstance?.slideTo(0);
        joinUsAndNewsSwiperInstance?.slideTo(0);
      }
      if (index === PageType.Products) {
        bodySwiperInstance.slideTo(0);
        homePageSwiperInstance.slideTo(1);
        bottomSwiperInstance?.slideTo(0);
        joinUsAndNewsSwiperInstance?.slideTo(0);
      }
      if (index === PageType.AboutUs) {
        bodySwiperInstance.slideTo(1);
        homePageSwiperInstance.slideTo(1);
        bottomSwiperInstance?.slideTo(0);
        joinUsAndNewsSwiperInstance?.slideTo(0);
      }
      if (index === PageType.JoinUs) {
        bodySwiperInstance.slideTo(1);
        homePageSwiperInstance.slideTo(1);
        bottomSwiperInstance?.slideTo(1);
        joinUsAndNewsSwiperInstance?.slideTo(0);
        clickBaiduEvent(push => {
          push(['_trackCustomEvent', 'click_joinus', {}]);
        });
      }
      if (index === PageType.NEWS) {
        bodySwiperInstance.slideTo(1);
        homePageSwiperInstance.slideTo(1);
        bottomSwiperInstance?.slideTo(1);
        joinUsAndNewsSwiperInstance?.slideTo(1);
      }
      setIsShowMenu(false);
    }
  };

  const maskClose = () => {
    setIsShowMenu(false);
    if (isShowLangSelect) {
      setTimeout(() => {
        setIsShowLangSelect(false);
      }, 600);
    }
  };

  const switchSelectLang = () => {
    setIsShowLangSelect(!isShowLangSelect);
  };

  const navListHeight = useMemo(() => {
    return (NavList.filter(n => n.show).length * 96) / 100;
  }, []);

  return (
    <header className={styles.mj_header_container}>
      <div>
        <Image
          width={205}
          height={72}
          alt="Manjuu"
          unoptimized
          src={LogoPath}
          className={styles.mj_header_logo}
        />
        <div
          className={styles.mj_openMenu_icon_body}
          onClick={() => {
            setIsShowMenu(!isShowMenu);
          }}>
          <div className={classNames([styles.mj_openMenu_icon])} />
        </div>
      </div>
      <div
        onClick={maskClose}
        className={classNames([
          styles.mj_header_menuContainer,
          isShowMenu
            ? styles.mj_header_menuContainer_show
            : styles.mj_header_menuContainer_hide,
        ])}>
        <CloseButton className={styles.mj_header_nav_closeBtn} />
        <nav
          style={{
            height: `${
              isShowLangSelect
                ? navListHeight <= (96 * 6) / 100
                  ? (96 * 6) / 100
                  : navListHeight
                : navListHeight + 0.83
            }rem`,
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          className={styles.mj_header_nav_container}>
          <div
            style={{
              transform: `translateY(${
                isShowLangSelect ? `${-navListHeight}rem` : 0
              })`,
            }}
            className={classNames([
              styles.mj_header_nav_list,
              isShowLangSelect && styles.mj_header_nav_list_hide,
            ])}>
            {NavList.map(
              nav =>
                nav.show && (
                  <div
                    className={classNames([
                      styles.mj_header_nav_item,
                      activePage === nav.type &&
                        styles.mj_header_nav_item_active,
                    ])}
                    key={nav.type}
                    onClick={() => {
                      if (nav.type === PageType.Others) {
                        dialog.open({
                          content: <ContactUs />,
                        });
                        setIsShowMenu(false);
                        return;
                      }
                      if (typeof nav.type === 'number') {
                        clickNav(nav.type);
                      }
                    }}>
                    <div className={styles.mj_header_nav_item_text}>
                      {nav.name}
                    </div>
                  </div>
                )
            )}
          </div>
          <div
            className={styles.mj_header_nav_selectLang_container}
            style={{
              transform: `translateY(${
                isShowLangSelect ? `${-navListHeight}rem` : 0
              })`,
            }}>
            <div
              onClick={switchSelectLang}
              className={styles.mj_header_nav_item}>
              <div className={styles.mj_header_nav_item_text}>
                <Icon fontClass="ICON-29" />
              </div>
            </div>
            {Object.entries(langMap).map(([k, v]) => (
              <div
                className={classNames([
                  styles.mj_header_nav_item,
                  lang === k && styles.mj_header_nav_item_active,
                ])}
                onClick={() => {
                  Cookies.set('lang', k, { expires: 30 });
                  location.href = `${location.origin}/${k}`;
                }}
                key={k}>
                <div className={styles.mj_header_nav_item_text}>{v}</div>
              </div>
            ))}
          </div>
        </nav>
      </div>
      <div
        onClick={maskClose}
        ref={maskRef}
        className={classNames([
          styles.mj_header_dark_mask,
          isShowMenu && styles.mj_header_dark_mask_show,
        ])}
      />
    </header>
  );
};

export default Header;
