export const createDialogRoot = (): HTMLDivElement => {
  const dialogRoot = document.getElementById('dialog');
  if (!(dialogRoot instanceof HTMLDivElement)) {
    const root = document.createElement('div');
    root.setAttribute('id', 'dialog');
    document.body.appendChild(root);
    return root;
  }
  return dialogRoot;
};
