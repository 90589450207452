import React from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import Image from 'next/image';
import { t } from '@lingui/macro';
import { useIsInternational } from '@/hooks/useIsInternational';

const record = require('@/public/images/recordation.png');

const Footer: React.FC = () => {
  const { lang, isLang, isInternational } = useIsInternational();

  return (
    <div
      className={classNames([
        styles.mj_footer_container,
        isLang('zh-tw') && styles.mj_footer_container_zh_tw,
      ])}>
      {isInternational && (
        <div className={styles.mj_footer_global_container}>
          <div className={styles.mj_footer_global_protocol}>
            <a
              target="_blank"
              className={styles.protocol_link}
              href={`/${lang}/protocol/privacy`}
              rel="noreferrer">{t`隐私政策`}</a>
            <div></div>
            <a
              target="_blank"
              className={styles.protocol_link}
              href={`/${lang}/protocol/userAgreement`}
              rel="noreferrer">{t`用户协议`}</a>
          </div>
          <div className={styles.mj_footer_global_bottom_text}>
            {isLang('kr')
              ? '© 만쥬코리아 유한회사 MANJUU KOREA Inc. All rights reserved.'
              : '©Manjuu Co.,Ltd.'}
          </div>
        </div>
      )}

      {isLang('zh') && (
        <>
          <div className={styles.mj_footer_title_bar}>版权信息</div>
          <div className={styles.mj_footer_content}>
            <div className={styles.mj_footer_content_right_protocol}>
              <a
                target="_blank"
                className={styles.protocol_link}
                href={`/${lang}/protocol/userAgreement`}
                rel="noreferrer">
                用户协议
              </a>
              <div className={styles.line} />
              <a
                target="_blank"
                className={styles.protocol_link}
                href={`/${lang}/protocol/privacy`}
                rel="noreferrer">
                隐私政策
              </a>
              <div className={styles.line} />
              <a
                target="_blank"
                className={styles.protocol_link}
                href={`/${lang}/protocol/childProtection`}
                rel="noreferrer">
                儿童隐私保护规则及监护人须知
              </a>
            </div>
            <div className={styles.mj_footer_healthGame}>
              <div>
                抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。
                <br />
                适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。
              </div>
              <div>COPYRIGHT©2017 上海蛮啾网络科技有限公司 </div>
              <div>
                <a href="https://beian.miit.gov.cn/">沪ICP备19021407号-3</a>{' '}
                沪网文〔2021〕2358-203号{' '}
                <span style={{ fontFamily: 'SimHei, sans-serif' }}>
                  ©Manjuu Co.,Ltd.
                </span>
              </div>
            </div>
            <div className={styles.registerSystemInfo}>
              <a
                style={{
                  color: '#BDBDBD',
                }}
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009828"
                rel="noreferrer">
                <Image
                  unoptimized
                  style={{
                    position: 'relative',
                    top: '2px',
                    marginRight: '12px',
                  }}
                  width={14}
                  height={14}
                  alt="公网安备"
                  src={record}
                />
                <span>沪公网安备 31010402009828号</span>
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
