import React, { createContext, useEffect, useRef, useState } from 'react';
import styles from './DialogContainer.module.less';
import Header from '../Header';
import { OpenProps } from 'components/Dialog';

export const DialogContext = createContext<{ hide: () => void }>({
  hide: () => {},
});

const DialogContainer: React.FC<OpenProps & { hide: () => void }> = ({
  header,
  content,
  footer,
  maskCloseable = true,
  headerProps,
  afterClose,
  width,
  hide,
}) => {
  const [isClose, setIsClose] = useState<boolean>(false);
  const maskRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      if (afterClose) {
        afterClose();
      }
      document.body.style.overflow = 'auto';
    };
  }, []);

  const delayHide = () => {
    setIsClose(true);
    hide();
  };

  const maskClose = (ev?: React.MouseEvent<HTMLElement>) => {
    if (maskCloseable && ev?.target === maskRef.current) {
      delayHide();
    }
  };

  return (
    <DialogContext.Provider
      value={{
        hide: delayHide,
      }}>
      <div
        ref={maskRef}
        onClick={maskClose}
        className={styles[`mask_${isClose ? 'close' : 'open'}`]}>
        <div
          style={{
            width: typeof width === 'number' ? `${width || 400}px` : width,
          }}
          className={`${
            styles[`DialogContainer_${isClose ? 'close' : 'open'}`]
          }`}>
          <>
            {header ? header : <Header {...headerProps} />}
            {typeof content === 'function'
              ? content({ hide: delayHide })
              : content}
            {footer}
          </>
        </div>
      </div>
    </DialogContext.Provider>
  );
};

export default DialogContainer;
