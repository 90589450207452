import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import { t } from '@lingui/macro';
import { useIsInternational } from '@/hooks/useIsInternational';

const ProductDetails: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const { lang, isLang } = useIsInternational();
  const [isShowDesc, setIsShowDesc] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowDesc(isActive);
    }, 580);
  }, [isActive]);

  const clickLinkOpenNewPage = (url: string) => {
    if (url) {
      window.open(url);
    }
  };

  return (
    <div
      style={{ pointerEvents: isActive ? 'auto' : 'none' }}
      className={styles.mj_productDetails_container}>
      <div className={styles.mj_productDetails_content}>
        <div
          className={classNames([
            styles.mj_productDetails_title_container,
            (isLang('kr') || isLang('jp')) &&
              styles.mj_productDetails_title_container_krOjp,
          ])}>
          <div
            className={classNames([
              styles.productsDetails_title_body,
              (isLang('kr') || isLang('jp')) &&
                styles.productsDetails_title_body_krOjp,
              isShowDesc && styles.productsDetails_title_body_show,
            ])}>
            <div
              className={styles.productsDetails_title_name}>{t`碧蓝航线`}</div>
            {!isLang('en') && (
              <div
                className={classNames([
                  styles.productsDetails_title_subTitle,
                  (isLang('kr') || isLang('jp')) &&
                    styles.productsDetails_title_subTitle_krOjp,
                ])}>
                / AZUR LANE
              </div>
            )}
          </div>
        </div>
        <div className={styles.mj_productDetails_desc_body}>
          <div
            lang={lang == 'zh-tw' ? 'zh' : lang}
            className={classNames([
              styles.mj_productDetails_desc,
              isShowDesc && styles.mj_productDetails_desc_show,
            ])}>
            {t`公司代表作《碧蓝航线》于2017年6月2日开始全平台公测，并已登陆海内外市场，取得优秀的成绩。
        游戏以舰船为题材，玩家可通过丰富的玩法，收集并养成强力的角色与装备，指挥属于自己的舰队征战四海。`}
          </div>
        </div>
      </div>

      <div
        className={classNames([
          styles.productsDetails_link_body,
          isShowDesc && styles.productsDetails_link_body_show,
        ])}>
        <div
          onClick={() => {
            const localeWebsite = {
              zh: 'https://game.bilibili.com/blhx/',
              ['zh-tw']: 'https://www.azurlane.tw/',
              jp: 'https://www.azurlane.jp/',
              kr: 'https://azurlane.xdg.com/',
              en: 'https://azurlane.yo-star.com/',
            };
            clickLinkOpenNewPage(localeWebsite[lang]);
          }}
          className={classNames([
            styles.productsDetails_link_website,
            styles.productsDetails_link_btn,
          ])}>
          <div
            className={classNames([
              styles.productsDetails_link_website_dot,
              styles.productsDetails_link_website_dot_left,
            ])}
          />
          {t`前往官网`}
          <div
            className={classNames([
              styles.productsDetails_link_website_dot,
              styles.productsDetails_link_website_dot_right,
            ])}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
