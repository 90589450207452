import React, { ReactNode } from 'react';
import { hideDialog } from 'components/Dialog/utils/hideDialog';
import { renderDialog } from 'components/Dialog/utils/renderDialog';
import { createDialogRoot } from 'components/Dialog/utils/createDialog';
import { DialogHeaderProps } from 'components/Dialog/components/Header';
import DialogContainer from 'components/Dialog/components/DialogContainer';

export interface OpenProps {
  header?: ReactNode;
  content?:
    | ReactNode
    | ((props: { hide: () => void }) => ReactNode | JSX.Element);
  width?: number | string;
  footer?: ReactNode;
  maskCloseable?: boolean;
  headerProps?: DialogHeaderProps;
  afterClose?: () => void;
}

class Dialog {
  open(props: OpenProps): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const rootElement = createDialogRoot();

        const hide = (): Promise<boolean> => {
          return hideDialog({ root, rootElement });
        };

        const { root } = renderDialog({
          rootElement,
          reactDialog: <DialogContainer hide={hide} {...props} />,
        });
        resolve(true);
      } catch (err) {
        reject(false);
        console.log(err);
      }
    });
  }
}

export const dialog = new Dialog();
