import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/pages';
import styles from './index.module.less';
import classNames from 'classnames';
import Drawer from 'components/Drawer';
import Honor from 'components/Honor';
import { t } from '@lingui/macro';
import { useIsInternational } from '@/hooks/useIsInternational';
import Icon from 'components/Icon';
import Footer from 'components/Footer';

const AboutUs: React.FC<{
  isActive: boolean;
}> = () => {
  const { bottomSwiperInstance, bodySwiperInstance, activePage } =
    useContext(GlobalContext);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false);
  const { isLang, lang } = useIsInternational();

  const clickShowDetail = () => {
    setIsShowDetail(!isShowDetail);
  };

  useEffect(() => {
    if (activePage !== 2) {
      setIsShowDetail(false);
    }
  }, [activePage]);

  useEffect(() => {
    if (bottomSwiperInstance && bodySwiperInstance && isShowDetail) {
      bottomSwiperInstance.disable();
      bodySwiperInstance.disable();
    }
  }, [bottomSwiperInstance, bodySwiperInstance, isShowDetail]);

  return (
    <div className={styles.mj_aboutUs_container}>
      <div
        className={classNames([
          styles.mj_aboutUs_content,
          isShowDetail && styles.mj_aboutUs_content_hide,
        ])}>
        <div className={styles.mj_aboutUs_content_title}>
          <h1 className={styles.mj_aboutUs_content_mainTitle}>{t`蛮啾网络`}</h1>
          {!isLang('en') && (
            <div className={styles.mj_aboutUs_content_subTitle}>
              {isLang('kr') ? 'Manjuu Korea' : 'Manjuu Games'}
            </div>
          )}
        </div>
        <div
          lang={lang == 'zh-tw' ? 'zh' : lang}
          className={styles.mj_aboutUs_content_detail}>
          {t`上海蛮啾网络科技有限公司（Manjuu Co., Ltd.）于2015年01月成立。因为LOGO标志性的形象“啾”和主题色，“蛮啾”也被玩家亲切地称之为“小黄鸡”。`}
          <br />
          <br />
          {t`目前，蛮啾主要作品为二次元战舰策略手游《碧蓝航线》，游戏相关产品已登陆海内外市场并取得优秀的成绩。同时，蛮啾一直致力于原创游戏的不断创新，更在原创IP、漫画、动画、周边等更多的领域上不断探索和努力，并有自研产品正在锐意开发中。`}
        </div>
      </div>

      <div
        className={classNames([
          styles.aboutUs_bottom_body,
          isShowDetail && styles.aboutUs_bottom_body_hide,
        ])}>
        <div
          onClick={clickShowDetail}
          className={classNames([
            styles.aboutUs_link_body,
            isLang('zh-tw') && styles.aboutUs_link_body_zh_tw,
          ])}>
          <div
            className={classNames([
              styles.aboutUs_link,
              styles.aboutUs_link_btn,
            ])}>
            <div
              className={classNames([
                styles.aboutUs_link_dot,
                styles.aboutUs_link_dot_left,
              ])}
            />
            {t`荣誉资质`}
            <div
              className={classNames([
                styles.aboutUs_link_dot,
                styles.aboutUs_link_dot_right,
              ])}></div>
            <div className={styles.aboutUs_link_subtitle}>ON THE RIGHT</div>
          </div>
          <div className={styles.aboutUs_link_icon}>
            <Icon
              className={styles.aboutUs_link_right_arrow}
              fontClass="ICON-28"
            />
          </div>
        </div>
        {isLang('zh-tw') ? (
          <div className={styles.aboutUs_zh_twFooter}>
            <Footer />
          </div>
        ) : (
          <div className={styles.aboutUs_scrollTips}>SCROLL - JOIN US</div>
        )}
      </div>

      <Drawer
        width="6.25rem"
        isShowMask
        clickMaskClosable
        isShowCloseButton={false}
        visible={isShowDetail}
        onClose={ev => {
          setIsShowDetail(ev);
          if (bottomSwiperInstance && bodySwiperInstance) {
            bottomSwiperInstance.enable();
            bodySwiperInstance.enable();
          }
        }}>
        <Honor isShowDetail={isShowDetail} />
      </Drawer>
    </div>
  );
};

export default AboutUs;
